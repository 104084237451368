import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";

import { getSectionColor } from "../utils/color";

import "../styles/video.css";

import Img from "gatsby-image";

import ReactPlayer from "react-player";

export const VideoTemplate = ({
  description,
  author,
  videoID,
  title,
  section,
  helmet,
  relatedArticles,
}) => {
  const colors = getSectionColor(section);

  const { primary, secondary } = colors;

  const mainRef = useRef(null);

  // console.log("Mobile Background Image", isMobile, primary, secondary, section);

  useEffect(() => {
    if (mainRef && mainRef.current) {
      mainRef.current.style.setProperty("--secondary-color", secondary);
      mainRef.current.style.setProperty("--primary-color", primary);
    }
  }, [mainRef]);

  const reactPlayerRef = useRef(null);
  const [muted, setMuted] = useState(false);

  const handleUnmute = () => {
    if (reactPlayerRef && reactPlayerRef.current) {
      const internalPlayer = reactPlayerRef.current.getInternalPlayer();

      if (internalPlayer) {
        internalPlayer.unMute();
        setMuted(true);
      }
    }
  };

  const handleMute = () => {
    if (reactPlayerRef && reactPlayerRef.current) {
      const internalPlayer = reactPlayerRef.current.getInternalPlayer();

      if (internalPlayer) {
        internalPlayer.mute();
        setMuted(false);
      }
    }
  };

  return (
    <section
      className='video-template'
      style={{
        objectFit: "cover",
      }}
      ref={mainRef}
    >
      {helmet || ""}

      <div className='flex flex-col'>
        <div className='flex h-auto min-h-screen w-full flex-col lg:flex-row'>
          <div className='flex flex-col justify-end h-full w-full lg:w-1/2 lg:flex-row lg:justify-start'>
            <div className='absolute text-white lg:self-end '>
              <button className='focus:outline-none' onClick={handleUnmute}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  className={`h-16 w-16  bottom-0 transition duration-1000  ${
                    muted ? "opacity-0 hidden" : "opacity-100 block"
                  }`}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z'
                    clipRule='evenodd'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2'
                  />
                </svg>
              </button>
              <button className='focus:outline-none' onClick={handleMute}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  className={`h-16 w-16 bottom-0 transition duration-1000 delay-1000  ${
                    !muted ? "opacity-0 hidden" : "opacity-100 block"
                  }
                }`}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z'
                  />
                </svg>
              </button>
            </div>
            <div
              className='lg:h-full w-full lg:pointer-events-none'
              onClick={handleUnmute}
            >
              <ReactPlayer
                ref={reactPlayerRef}
                url={`https:/www.youtube-nocookie.com/watch?v=${videoID}?autoplay=1&enablejsapi=1&modestbranding=1&showinfo=0`}
                width='100%'
                height='100vh'
                playsinline={true}
                loop={true}
                muted={false}
                config={{
                  youtube: {
                    playerVars: {
                      autoplay: 1,
                      mute: 1,
                      enablejsapi: 1,
                      modestbranding: 1,
                      showinfo: 0,
                      loop: 1,
                      rel: 0,
                      ecver: 2,
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className='lg:w-1/2'>
            <div class='max-w-4xl h-full mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center'>
              <div class='flex flex-col title bg-white bg-opacity-50 w-full lg:rounded-full justify-center items-center lg:items-center'>
                <p class='text-3xl font-extrabold text-center'>{title}</p>
                <p class='text-2xl font-bold text-center'>{description}</p>
                <p class='text-xl font-bold text-center'>{author}</p>
              </div>
            </div>
          </div>
        </div>

        <div className='hidden lg:block '>
          <div class='max-w-7xl h-full mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center'>
            <div class='flex flex-col justify-center items-center lg:items-center w-full'>
              <div class='flex w-3/4 justify-center h-screen items-center'>
                <ReactPlayer
                  url={`https://www.youtube-nocookie.com/watch?v=${videoID}?&modestbranding=1&showinfo=0&rel=0&ecver=2`}
                  playsinline={true}
                  loop={true}
                  width='80%'
                  height='60%'
                  muted={false}
                  config={{
                    youtube: {
                      playerVars: {
                        controls: 1,
                        enablejsapi: 1,
                        modestbranding: 1,
                        showinfo: 0,
                        rel: 0,
                        ecver: 2,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='related-articles w-full flex flex-col justify-center'>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div class='max-w-3xl  mx-auto text-white'>
              <p className='text-3xl font-bold text-center'>Related Articles</p>
            </div>
          </div>
          <div className='flex flex-col lg:flex-row justify-around w-full items-start'>
            {relatedArticles.map((article) => {
              return (
                <Link to={article.fields.slug}>
                  <div class='py-6'>
                    <div class='flex flex-col max-w-md bg-white shadow-lg rounded-lg overflow-hidden h-full'>
                      <div>
                        <Img
                          className='object-cover'
                          fluid={
                            article.frontmatter.featuredimage?.childImageSharp
                              .fluid
                          }
                        ></Img>
                      </div>
                      <div class='p-4'>
                        <h1 class='text-gray-900 font-bold text-xl'>
                          {article.frontmatter.title}
                        </h1>
                        <p class='mt-2 text-gray-600 text-sm'>
                          {article.frontmatter.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      {/* <PostContent content={content} /> */}
    </section>
  );
};

VideoTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const VideoPost = ({ data }) => {
  const { markdownRemark: post, allMarkdownRemark: section } = data;

  const relatedArticles = section.nodes.filter(
    (element) => element.id !== post.id
  );

  return (
    <Layout>
      <VideoTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate='%s | Blog'>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name='description'
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        author={post.frontmatter.author}
        videoID={post.frontmatter.videoID}
        title={post.frontmatter.title}
        section={post.frontmatter.section}
        relatedArticles={relatedArticles}
      />
    </Layout>
  );
};

VideoPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default VideoPost;

export const pageQuery = graphql`
  query VideoPostByID($id: String!, $section: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        description
        author
        videoID
        section
      }
    }
    allMarkdownRemark(filter: { frontmatter: { section: { eq: $section } } }) {
      totalCount
      nodes {
        fields {
          slug
        }
        id
        frontmatter {
          title
          featuredimage {
            childImageSharp {
              fluid(maxWidth: 2048, maxHeight: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          description
        }
      }
    }
  }
`;
